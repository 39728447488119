export default function OtherSections(props){
    return (
        <section style={{display: "none"}} className="other-sections-container">
            <div className="wrapper">
                <h1>Why Sell to MIOYM?</h1>
                <div>
                    <p>
                    After speaking with us about the property, we can either give you an offer, 
                    or schedule a showing with us of the property so we can make our best offer. 
                    At minimum, we are willing to give you our best cash offer on the property, 
                    a valuable opinion from a seasoned operator of residential real estate in 
                    Baltimore City and County. We take pride in bringing our sellers to settlement with 
                    convenience and ease, always willing to provide transportation for local and distant sellers. 
                    We know that there is a story behind every property we buy that requires some important 
                    closure for the seller. Therefore we always handle the purchase of your property with respect, 
                    fairness, and integrity – hallmarks that we have become known for over 15 years or purchasing 
                    properties throughout Maryland.
                    </p>
                </div>
            </div>
        </section>
    )
} 