import investorImg from "../../../images/investorpic.jpg";
import loanHighLights from "../../../images/loanhighlights.jpg";
import requirementspic from "../../../images/requirementspic.jpg"

export default function FixAndFlipMainSection(){
    return (
        <section className="fix-and-flip-main-section">
            <div className="wrapper">
                <h1 style={{marginBottom: 50}}>
                    100% FINANCING FOR EXPERIENCED INVESTORS
                </h1>
                <div className="fix-and-flip-main-section-content">
                    <div className="fix-and-flip-section-item">
                        <div className="fix-and-flip-item-section image"  style={{backgroundImage: `url('${loanHighLights}')`}}>
                            
                        </div>
                        <div className="fix-and-flip-item-section info">
                            <p style={{color: "skyblue", fontSize: 17, textAlign: "center", fontWeight: "bolder"}}>LOAN HIGHLIGHTS</p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                100% on Purchase & Rehab
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Min Loan Amount: $150,000
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Max Loan Amount: $1.5mil
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                10-11% and 2-3 Orig Pts
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                9 Month Interest Only
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Interest Paid on Funds Disbursed Only
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Repair Budget Not to Exceed 30% of Total Loan Amount
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                150$ Draw Fee
                            </p>
                            <p style={{color: "white", textAlign: "center", color: "lightgreen", marginTop: 20}}>
                                (as many or few as you like)
                            </p>
                        </div>
                    </div>
                    <div className="fix-and-flip-section-item reverse-flex">
                        <div className="fix-and-flip-item-section image" style={{backgroundImage: `url('${investorImg}')`}}>

                        </div>
                        <div className="fix-and-flip-item-section info">
                            <p style={{color: "skyblue", fontSize: 17, textAlign: "center", fontWeight: "bolder"}}>THE INVESTOR</p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Min FICO 620
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Must Have Completed Flips in Last 36 Months
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Less than 10 (at 65% ARV)
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                10+ Flips (at 70% ARV)
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Must be Able to Document Prior Flips w/HUDS
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Must Have Min $30,000 Cash on Hand
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Must Live w/in 100 Miles of Subject Property
                            </p>
                        </div>
                    </div>
                    <div className="fix-and-flip-section-item">
                        <div className="fix-and-flip-item-section image"  style={{backgroundImage: `url('${requirementspic}')`}}>
                            
                        </div>
                        <div className="fix-and-flip-item-section info">
                            <p style={{color: "skyblue", fontSize: 17, textAlign: "center", fontWeight: "bolder"}}>REQ ITEMS FOR CREDIT APPROVAL</p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Loan App
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Drivers License
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Current Year's Tax Return
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Entity Docs (OA, By-laws,Articles,SS4) Pics (interior/exterior)
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                3 Comps
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Itemized Repair Budget
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                Executed Contract w/any Assignments
                            </p>
                            <p style={{color: "white"}}>
                                <i className="fa-solid fa-circle" style={{color: "orange", marginRight: 10}}></i>
                                No properties in AZ, NV, HI
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}