import { Component } from "react";
import Header from "../../components/Header";
import MainSection from "./sections/MainSection";
import Footer from "../../components/Footer";

class ApplyNowPage extends Component{
    render(){
        
        return (
            <>
                <Header header="apply" />
                <MainSection />
                <Footer />
            </>
        )
    }
}

export default ApplyNowPage;