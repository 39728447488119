import { Component } from "react";

import HeroSection from "../../components/HeroSection";
import HeroBgVideo from "../../components/HeroBgVideo";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Main from "./sections/MainSection";
import OtherSections from "./sections/OtherSections";
import AboutUs from "../../components/AboutUs";
import LearnTheater from "../../components/LearnTheater";
import FixAndFlipMainSection from "./sections/FixAndFlipMainSection";
import CookiePrompt from "../../components/CookiePrompt";

class HomePage extends Component {
    constructor(props){
        super(props);
    }

    /*loadGreeting = async() => {
        const response = await fetch('http://localhost:4000/graphql', {
            method:'POST',
            headers:{'content-type':'application/json'},
            body:JSON.stringify({query:`{
                    hello
                }`
            })
        });

        const rsponseBody = await response.json();
        console.log("response ", rsponseBody.data.hello);
        return rsponseBody.data.hello
    }*/

    render(){
        //console.log(this.loadGreeting());
       return (
           <>
                <CookiePrompt />
                <Header header="home"/>
                <HeroBgVideo />
                <HeroSection />
                <AboutUs />
                <FixAndFlipMainSection />
                <OtherSections />
                <Main />
                <Footer />
            </>
        )
    }
}

export default HomePage;