import { Link } from "react-router-dom";
import rentalloanimg from "../images/rentalloanimg.jpg";
import brooklynbridge from "../images/brooklynbridge.jpg";

export default function AboutUs() {
    return (
        <section className="about-us-section">
            <div className="wrapper">
                <section className="programs-page-main-section" style={{background: "none", padding: "20px 0", marginTop: 70, minHeight: "auto"}}>
                    <h1 style={{color: "orange"}}>SELL YOUR HOUSE FAST!</h1>
                    
                    <div className="programs-section-main-flex-container">
                        <div className="programs-section-each-program-item" style={{minHeight: "auto"}}>
                            <p style={{color: "white", textAlign: "center", fontSize: 16, fontWeight: "bolder"}}>
                                FOR ANY SITUATION</p>
                            <div style={{marginTop: 15}}>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Foreclosures
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Divorce
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Financial Struggles
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Relocation
                                </p>
                            </div>
                        </div>
                        <div className="programs-section-each-program-item" style={{minHeight: "auto"}}>
                            <p style={{color: "white", textAlign: "center", fontSize: 16, fontWeight: "bolder"}}>
                                IN ANY CONDITION</p>
                            <div style={{marginTop: 15}}>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Structural Damage
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Unfinished Interiors 
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Hoarder Home
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Major Repairs Needed
                                </p>
                            </div>
                        </div>
                        <div className="programs-section-each-program-item" style={{minHeight: "auto"}}>
                            <p style={{color: "white", textAlign: "center", fontSize: 16, fontWeight: "bolder"}}>
                                WITH ANY STRUGGLES</p>
                            <div style={{marginTop: 15}}>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Code Violations
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Low Equity
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Bad Tenants
                                </p>
                                <p style={{color: "skyblue", fontSize: 14}}>
                                    <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                    Late Mortgage Payments
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div style={{marginTop: 20, display: "none"}}>
                    <h1>Services Offered</h1>
                    <div className="services-offerd-container">
                        <div className="services-offerd-item">
                            <div className="services-offerd-item-top">

                            </div>
                            <div className="services-offerd-item-bottom">
                                <p>FIX AND FLIP LOANS</p>
                                <p>
                                    Short-term financing for the purchase & rehab of non-owner occupied properties.
                                </p>
                            </div>
                            <div className="services-offerd-item-center">
                                
                            </div>
                        </div>
                        <div className="services-offerd-item">
                            <div className="services-offerd-item-top" style={{backgroundImage: `url('${rentalloanimg}')`}}>

                            </div>
                            <div className="services-offerd-item-bottom">
                                <p>RENTAL LOANS</p>
                                <p>
                                    Long-term financing for investors who are holding properties for rental income.
                                </p>
                            </div>
                            <div className="services-offerd-item-center">
                                
                            </div>
                        </div>
                        <div className="services-offerd-item">
                            <div className="services-offerd-item-top" style={{backgroundImage: `url('${brooklynbridge}')`}}>

                            </div>
                            <div className="services-offerd-item-bottom">
                                <p>BRIDGE LOANS</p>
                                <p>
                                    Short-term financing for residential, commercial & mixed-use properties.
                                </p>
                            </div>
                            <div className="services-offerd-item-center">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}