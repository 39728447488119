import { Link } from "react-router-dom";

export default function ProgramsMainSection(){
    return (
        <section className="programs-page-main-section">
            <div className="wrapper">
                <h1>LOAN PROGRAMS</h1>
                <div className="programs-section-main-flex-container">
                    <div className="programs-section-each-program-item">
                        <p style={{color: "white", textAlign: "center", fontSize: 16, fontWeight: "bolder"}}>
                            FIX & FLIP/ CONSTRUCTION</p>
                        <p style={{color: "orange", textAlign: "center", fontSize: 14}}>
                            100% Fix and Flip Financing
                        </p>
                        <div style={{marginTop: 15}}>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                SFR 1-4, MULTIFAMILY 5-20+, CONDO, TOWNHOME, MIXED-USE (SELECT COMMERCIAL REHAB)
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                TERM: 12-18 MONTHS
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                NO PREPAY PENALTY
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                RATES STARTING AT 7.49%
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                PURCHASE, REFI, CASH OUT
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                FOREIGN NATIONAL OK
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                HEAVY REHAB OK
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                650 MIN FICO
                            </p>
                        </div>
                        <Link to="/apply-now">
                            <div style={{backgroundColor: "darkslateblue", textAlign: "center", width: 120, margin: "auto", marginTop: 30, padding: 14, color: "white", cursor: "pointer", borderRadius: 6, boxShadow: "0 0 10px rgba(0,0,0,0.6)", fontSize: 14}}>
                                Apply
                            </div>
                        </Link>
                    </div>
                    <div className="programs-section-each-program-item">
                        <p style={{color: "white", textAlign: "center", fontSize: 16, fontWeight: "bolder"}}>
                            BRIDGE</p>
                        <p style={{color: "orange", textAlign: "center", fontSize: 14}}>
                            SFR 1-4, MULTIFAMILY 5-20, MULTIFAMILY 20+, CONDO, TOWNHOME, MIXED-USE, COMMERCIAL
                        </p>
                        <div style={{marginTop: 15}}>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                LOAN MIN: $75K LOAN MAX: $10+ MILLION
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                65-85% LTV
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                TERM: UP TO 36 MONTHS
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                RATES STARTING AT 5.5%
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                PURCHASE, REFI, CASH OUT
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                FOREIGN NATIONAL OK
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                650 MIN FICO
                            </p>
                        </div>
                        <Link to="/apply-now">
                            <div style={{backgroundColor: "darkslateblue", textAlign: "center", width: 120, margin: "auto", marginTop: 30, padding: 14, color: "white", cursor: "pointer", borderRadius: 6, boxShadow: "0 0 10px rgba(0,0,0,0.6)", fontSize: 14}}>
                                Apply
                            </div>
                        </Link>
                    </div>
                    <div className="programs-section-each-program-item">
                        <p style={{color: "white", textAlign: "center", fontSize: 16, fontWeight: "bolder"}}>
                            RENTAL HOLD</p>
                        <p style={{color: "orange", textAlign: "center", fontSize: 14}}>
                        SFR 1-4, MULTIFAMILY  5+, CONDO, TOWNHOME, MIXED-USE
                        </p>
                        <div style={{marginTop: 15}}>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                LOAN MIN: $75K LOAN MAX: $5+ MILLION
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                PURCHASE, REFI, CASH OUT
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                30 YR FIXED (3YR, 7YR, 10YR FIXED) OR 2+1
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                RATES FROM  5.5%
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                FOREIGN NATIONAL OK
                            </p>
                            <p style={{color: "skyblue", fontSize: 14}}>
                                <i style={{color: "orange", marginRight: 10}} className="fa-solid fa-check"></i>
                                650 MIN FICO
                            </p>
                            <p style={{textAlign: "center", color: "white", fontSize: 12, marginTop: 20}}>
                                <i style={{fontSize: 20, color: "yellow", marginRight: 10}} className="fa-solid fa-info-circle"></i>
                                PORTFOLIOS/BLANKETS REQUIRE MIN VALUE OF $75K PER PROPERTY
                            </p>
                        </div>
                        <Link to="/apply-now">
                            <div style={{backgroundColor: "darkslateblue", textAlign: "center", width: 120, margin: "auto", marginTop: 30, padding: 14, color: "white", cursor: "pointer", borderRadius: 6, boxShadow: "0 0 10px rgba(0,0,0,0.6)", fontSize: 14}}>
                                Apply
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}