import { Link } from "react-router-dom"

export default function HeroSection(){
    return (<section className="hero classic-hero">
        <div className="content">
            <div className="wrapper" style={{width: "100%"}}>
                <p style={{textAlign: "center", color: "white", fontSize: 26, fontWeight: "bolder"}}>
                    PiN Software Page<br/>
                </p>
                
            </div>
        </div>
    </section>)
}