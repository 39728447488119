import { Component } from 'react'

//components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ProgramsMainSection from './sections/ProgramsMainSection';

class ProgramsPage extends Component{
    constructor(props){
        super(props);
    }


    render(){
        return(
            <>
                <section className='page-container'>
                    <Header header="programs" />
                        <ProgramsMainSection />
                    <Footer />
                </section>
            </>
        );
    }
}

export default ProgramsPage;