import MobileMenu from '../components/MobileMenu';
import {ToggleShowHideMobileMenu} from '../components/MobileMenu';

import { Link } from 'react-router-dom';
import { Component } from 'react';

import logo from "../images/logo.jpg"

class Header extends Component{

    constructor(props){
        super(props);
        
    }


    render(){
        
        return (
            <header style={{backgroundColor: this.props.background}}>
                <div className="wrapper header-wrapper">
                    <div className="content">
                        <img src={logo} style={{display: "none", width: 50, height: 50, marginTop: 10}} />
                        <Link to="/"><div style={{}} className="logo">
                            <p className='logo-title' style={{fontWeight: "bolder", color: "orange", margin: 0, marginTop: 15,}}>
                                MIOYM Properties</p></div></Link>
                        <div style={{marginTop: 20}}>
                            <span style={{color: "orange", textShadow: "1px 2px 3px rgba(0,0,0,0.4)"}}>
                                <i className='fa fa-phone' style={{marginRight: 10}}></i>
                                914-566-9050</span>
                        </div>
                        <div style={{display: "none"}} className='top-nav-social-btns'>
                            <a href="tel:+1 888 976 4696" style={{color: "rgba(0,0,0,0.7)"}}>
                                <span><i className='fa fa-phone'></i></span></a>
                            <a target="_blank" href="mailto:info@mioymcommercialcapital.com" style={{color: "rgba(0,0,0,0.7)"}}>
                                <span><i className='fa fa-envelope'></i></span></a>
                            <a target="_blank" href="https://business.facebook.com/mioymcommercialcapitalny/?business_id=2217779521880362" style={{color: "rgba(0,0,0,0.7)"}}>
                                <span><i className='fa fa-facebook'></i></span></a>
                            
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;