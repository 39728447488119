import investorImg from "../../../images/investorpic.jpg";
import loanHighLights from "../../../images/loanhighlights.jpg";
import requirementspic from "../../../images/requirementspic.jpg"

export default function FixAndFlipMainSection(){
    return (
        <section className="fix-and-flip-main-section" style={{padding: 0, paddingTop: 150}}>
            <div className="wrapper">
                
                <div className="fix-and-flip-main-section-content">
                    <div className="fix-and-flip-section-item">
                        <div className="fix-and-flip-item-section image"  style={{backgroundImage: `url('${loanHighLights}')`}}>
                            
                        </div>
                        <div className="fix-and-flip-item-section info" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            <p style={{color: "skyblue", fontSize: 17, textAlign: "center", fontWeight: "bolder"}}>
                                1. SCHEDULE A CONSULTATION</p>
                            <p style={{color: "white", textAlign: "center"}}>
                                We'll review your goals, current situation and go over all possible solutions with you.
                            </p>
                        </div>
                    </div>
                    <div className="fix-and-flip-section-item reverse-flex">
                        <div className="fix-and-flip-item-section image" style={{backgroundImage: `url('${requirementspic}')`}}>

                        </div>
                        <div className="fix-and-flip-item-section info" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            <p style={{color: "skyblue", fontSize: 17, textAlign: "center", fontWeight: "bolder"}}>
                                2. GET A FAIR OFFER</p>
                            <p style={{color: "white", textAlign: "center"}}>
                                If a direct sale proves to be the best choice, we will provide you with a no-obligation offer.
                            </p>
                        </div>
                    </div>
                    <div className="fix-and-flip-section-item">
                        <div className="fix-and-flip-item-section image"  style={{backgroundImage: `url('${investorImg}')`}}>
                            
                        </div>
                        <div className="fix-and-flip-item-section info"  style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            <p style={{color: "skyblue", fontSize: 17, textAlign: "center", fontWeight: "bolder"}}>
                                3. GET PAID VIA TITLE COMPANY</p>
                            <p style={{color: "white", textAlign: "center"}}>
                                If you accept our offer, we will pay you cash with a reputable, local title company.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}