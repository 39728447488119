/*import landingBgVideo from "../PropertiesLand.mp4";
{landingBgVideo}{landingBgVideo}*/
import { Link } from "react-router-dom";

export default function HeroBgVideo(){
    return (<video style={{display: "none"}} loop autoPlay muted className="hero-bg-video">
        <source src="" type="video/mp4" />
        <source src="" type="video/ogg" />
        Your browser does not support the video tag.
       
    </video>)
}