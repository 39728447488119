export default function AboutMain(){
    return (
        <section className="about-main-section-container">
            <div className="wrapper">
                <div className="about-section-about-text-container">
                    <h1>ABOUT</h1>
                    <p>
                    Mioym Commercial Capital ("MCC") was established in 2008 to provide financing 
                    to real estate investors to fund the purchase of non-owner occupied residential 
                    and commercial properties, provide commercial bridge loans, and provide real 
                    estate-backed lines of credit.  Formed towards the end of the recession, 
                    MCC has benefited from a turbulent housing market in which many real estate 
                    investors see great opportunity.  A lack of liquidity in the credit markets 
                    and increased regulation of the banking industry has restricted conventional 
                    financing as an option for investors in fast-moving and competitive real estate markets.  
                    As the nation’s premier direct private lender, MCC helps fill this void.
                    </p>
                    <p>
                    MCC is a nationwide direct lender.   We provide short-term funding for 
                    fix & flips, rehabbing, ground up construction and conversions. 
                    We also offer long term programs for rental holds. Our hard money 
                    loans range from $50k to $10MM+ for the purpose of acquisition, 
                    refinancing, cash-out, bridge and construction. 
                    We lend on non-owner occupied investment and commercial properties.
                    </p>
                    <p>
                    MCC takes a common sense approach to underwriting, with all approvals made in-house.  
                    We are dedicated to providing quick responses to time-sensitive loans, often times 
                    with the ability to close in as few as 7-10 days.  At MCC, we value referrals and 
                    our brokers are always protected.  We are committed to the highest 
                    level of customer service because our success lies in building relationships
                    </p>
                    <p>
                    Mioym Commercial Capital lends to real estate professionals, 
                    investors, developers, contractors and specialists throughout the United States.
                    </p>
                </div>
            </div>
        </section>
    );
}