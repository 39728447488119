import { Component } from 'react'

//components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BrokersMainSection from './sections/BrokersMainSection';

class BrokersPage extends Component{
    constructor(props){
        super(props);
    }


    render(){
        return(
            <>
                <section className='page-container'>
                    <Header header="brokers" />
                    <BrokersMainSection />
                    <Footer />
                </section>
            </>
        );
    }
}

export default BrokersPage;