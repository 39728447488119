export default function Footer(){
    return <footer>
        <div className="wrapper">
            <div className="container" style={{justifyContent: "center"}}>
                <div className="section">
                <div className="logo">
                    <p style={{fontWeight: "bolder", color: "orange", fontSize: 23, lineHeight: 1, textAlign: "center"}}>
                        MIOYM Properties<br/>
                        
                    </p></div>
                    <div>
                        <p style={{color: 'orange', textAlign: "center", margin: 0, fontSize: 14}}>
                            <i style={{marginRight: 10, color: "orange"}} class="fa-solid fa-map-marker"></i>
                            800 Westchester Avenue,
                            <br/>
                            <span style={{color: "orange", fontSize: 14}}>
                            Rye Brook, NY 10573
                            </span>
                        </p>
                        
                        <p style={{color: 'orange', textAlign: "center", margin: 0, fontSize: 14}}>
                            <i style={{marginRight: 10, color: "orange"}} class="fa-solid fa-phone"></i>
                            888 97 MIOYM | 888 976 4696
                        </p>
                        <p style={{color: 'orange', textAlign: "center", margin: 0, fontSize: 14, marginTop: 5}}>
                            <i style={{marginRight: 10, color: "orange"}} class="fa-solid fa-fax"></i>
                            (888) 801-9401
                        </p>
                    </div>
                    <p style={{color: 'white', textAlign: "center"}}>
                        <span style={{margin: "0 7px"}}><i style={{fontSize: 22}} className="fa-solid fa-envelope"></i></span>
                        <span style={{margin: "0 7px"}}><i style={{fontSize: 22}} className="fa-solid fa-phone"></i></span>
                        <span style={{margin: "0 7px"}}><i style={{fontSize: 22}} className="fa-brands fa-linkedin"></i></span>
                        <span style={{margin: "0 7px"}}><i style={{display: "none", fontSize: 22}} className="fa-brands fa-twitter"></i></span>
                        <span style={{margin: "0 7px"}}><i style={{fontSize: 22}} className="fa-brands fa-facebook"></i></span>
                    </p>
                    <p style={{fontSize: 13, color: "rgba(255,255,255,0.5)", fontWeight: "initial", textAlign: "center"}}>
                            all rights reserved &copy; 2022</p>
                </div>
                

            </div>
        </div>
    </footer>
}