import $ from "jquery";

function toggleShowTerms(){
    if(document.getElementById("terms-and-conditions-container").style.display==="none"){
        $("#terms-and-conditions-container").slideDown("fast");
    }else{
        $("#terms-and-conditions-container").slideUp("fast");
    }
}

export default function BrokersMainSection(){
    return (
        <section className="brokers-page-main-section">
            <div className="brokers-page-hero hero">
                <div className="wrapper" style={{height: "100%"}}>
                    <div style={{height: "calc(100% - 250px)", display: "flex", flexDirection: "column", justifyContent: "center", padding: 15, paddingBottom: 130, paddingTop: 30}}>
                        <h1 style={{textAlign: "center", color: "white", textShadow: "0 0 10px rgba(0,0,0,0.404)", fontSize: 25}}>
                            BROKERS WELCOME & PROTECTED</h1>
                        <p style={{marginTop: 30, color: "white", textAlign: 'center', fontWeight: "bolder"}}>
                            Why Choose Mioym Commercial Capital?
                        </p>
                        <p style={{color: "white", textAlign: 'center'}}>
                            MCC appreciates brokers and works hard to earn their business.  
                            We respect and preserve the broker/borrower relationship while 
                            striving to keep all parties content throughout the loan process. 
                            We are always looking to establish 
                            mutually beneficial business relationships. Register with us today
                        </p>
                    </div>
                </div>
            </div>
            <div className="brokers-page-body-content-section-container" style={{width: "calc(100% - 40px)", margin: "auto", marginTop: -130, borderRadius: 50, marginBottom: 50, maxWidth: 1300, padding: "15px", backgroundColor: "rgb(0, 30, 41)", position: "relative", zIndex: 10, boxShadow: "0 0 10px rgba(0,0,0,0.6)"}}>
                <h1 style={{color: "orange", textAlign: "center", marginBottom: 20, fontSize: 25}}>
                    REGISTRATION
                </h1>
                <div className="how-it-works-section">
                    <div className="how-it-works-section-step">
                        <p style={{color: "white", fontSize: 13, margin: 5, marginBottom: 25}}>
                            Fill the form</p>
                        <div className="how-it-works-section-step-number">1</div>
                    </div>
                    <div className="how-it-works-section-step">
                        <p style={{color: "white", fontSize: 13, margin: 5, marginBottom: 25}}>
                            Read the terms</p>
                        <div className="how-it-works-section-step-number">2</div>
                    </div>
                    <div className="how-it-works-section-step">
                        <p style={{color: "white", fontSize: 13, margin: 5, marginBottom: 25}}>
                            Accept and Submit</p>
                        <div className="how-it-works-section-step-number">3</div>
                    </div>
                </div>
                <div className="broker-registration-form-container">
                    <div className="wrapper">
                        <div className="broker-registration-form-content">
                            <div className="broker-registration-form-section">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="First Name" />
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Last Name" />
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Company Name" />
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Fax Number" />
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Suite Number" />
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Time Zone" />
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="EIN Number" />
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="NMLS License Number" />
                                </div>
                                <select className="bare-form-input" style={{width: "calc(100%)"}}>
                                    <option>
                                        Broker/Partner Type
                                    </option>
                                </select>
                            </div>
                            <div className="broker-registration-form-section">
                                <div style={{marginBottom: 15}}>
                                    <p style={{color: "orange"}}>Select Agent Type</p>
                                    <div style={{marginBottom: 5}}>
                                        <input type="checkbox" />
                                        <label><span style={{color: "white", marginLeft: 10}}>Broker</span></label>
                                    </div>
                                    <div>
                                        <input type="checkbox" />
                                        <label><span style={{color: "white", marginLeft: 10}}>Loan Officer</span></label>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Phone Number" />
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Email" />
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Street Address" />
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="City" />
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="State/Region" />
                                    <input className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Postal code" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <p onClick={toggleShowTerms} style={{color: "white", padding: 10, textDecoration: "underline", color: "red", cursor: "pointer", fontSize: 12, textAlign: "center"}}>
                        <i className="fa-solid fa-info-circle" style={{marginRight: 10, color: "yellow"}}></i>
                        CLICK TO READ TERMS AND CONDITIONS</p>
                    <div id="terms-and-conditions-container" style={{display: "none", padding: 10, borderTop: "1px solid rgba(255,255,255,0.1)"}}>
                        <p style={{textAlign: "center", color: "orange"}}>TERMS AND CONDITIONS</p>
                        <p style={{color: "white"}}>
                            Lender is in the business of originating, processing, funding, closing, and servicing loans secured by real estate. Broker is a mortgage loan broker and desires to submit loans to Lender from time to time for consideration by Lender for funding. Lender and Broker ("Party or Parties") wish to establish a nonexclusive relationship whereby Broker submits loan packages ("Loan" or "Loans") to Lender on behalf of Broker's clients ("Borrower" or "Borrowers") for possible funding. In consideration of the mutual covenants and conditions contained herein and for other good and valuable consideration, the Parties agree as follows:
                        </p>
                        <p style={{color: "white"}}>
                            1. Loan Submission and Approval - Subject to the terms, conditions and limitations contained in this Agreement, Broker agrees to submit, and Lender agrees to review Loans. Broker acknowledges and agrees that the decision to make or not make any Loan is made exclusively by Lender in its sole and absolute discretion. Broker shall not represent that Lender has approved or will approve any Loan until Broker is so informed by Lender in writing. All Loans shall close in Lender's name. Lender is under no obligation to accept any minimum number of percentage of loans, or any loans at all. Lender reserves the right to decline any loan application or credit request that Lender deems, in its sole and absolute discretion, as "predatory" or as not having any reasonable benefit to the Borrower.
                        </p>
                        <p style={{color: "white"}}>
                            2. Status of Broker - Nothing in this Agreement shall be construed as making Broker an agent, employee, representative, joint venture, or partner of Lender. Broker will not hold itself out as such, nor will it use Lender's name in any advertising or solicitation without Lender’s prior written consent. Broker shall be deemed to be acting as an independent contractor, agent, and representative of the loan applicant, and not that of the Lender. During the term of this Agreement, Broker is responsible for, and shall pay in full, all costs and expenses directly or indirectly incurred by Broker in performing under this Agreement. Broker will not in any way represent or imply to any Borrower that Broker is in any way affiliated with or connected to Lender, including any representation that Broker’s office is an office or branch of Lender, or that Broker has any power or authority to bind Lender in any manner whatsoever. No statement or representation of Broker shall be published by an authorized employee of Lender.
                        </p>
                        <p style={{color: "white"}}>
                            3. Compensation to Broker - No fee shall be owed to Broker on any proposed loan that is not funded and closed. Broker waives and relinquishes any and all claims against Lender for professional services rendered by the Broker to the Borrower. Broker agrees that such compensation shall represent the market value for actual, non-duplicative services, including, but not limited to, interviewing and counseling the Borrower, as well as procuring and submitting to Lender the loan application, credit reports, property appraisal, and other information required to close the loan. It is agreed and understood that Lender is relying on the accuracy of the information submitted by Broker and contained in the Loan Estimate. Should Lender determine the information used to populate the Loan Estimate is not correct, or was not submitted in good faith, per RESPA guidelines, Lender reserves the right to not accept any and all files submitted incorrectly or that are otherwise not in compliance with applicable laws and regulations.
                        </p>
                        <p style={{color: "white"}}>
                            You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.
                        </p>
                        <p onClick={toggleShowTerms} style={{color: "white", padding: 10, textDecoration: "underline", color: "red", cursor: "pointer", fontSize: 12, textAlign: "center"}}>
                            HIDE
                            <i className="fa-solid fa-caret-up" style={{marginLeft: 10, color: "yellow"}}></i>
                        </p>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                        <input type="checkbox" />
                        <label><span style={{color: "orange", marginLeft: 10}}>By checking this box I agree to the terms and conditions.</span></label>
                    </div>
                    <div style={{cursor: "pointer", width: 150, margin: "auto", padding: 14, backgroundColor: "darkslateblue", color: "white", textAlign: "center", borderRadius: 50, marginTop: 20}}>
                        Submit
                    </div>
                </div>
            </div>
        </section>
    );
}