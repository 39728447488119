import { Component } from 'react'

//components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AboutMain from './sections/AboutMain';

class AboutPage extends Component{
    constructor(props){
        super(props);
    }


    render(){
        return(
            <>
                <section className='page-container'>
                    <Header header="about" background="black" />
                        <AboutMain />
                    <Footer />
                </section>
            </>
        );
    }
}

export default AboutPage;