import { Component } from "react";

import LoginHeroSection from "../../components/LoginHeroSection";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class LoginPage extends Component {
    constructor(props){
        super(props);
    }
    render(){
       return (
           <>
                <Header />
                <LoginHeroSection />
                <Footer />
            </>
        )
    }
}

export default LoginPage;