import moneyicon from "../icons/moneyicon.png";
import searchicon from "../icons/searchicon.png";
import realestate from "../icons/realestate.png";
import infoicon from "../icons/infoicon.png";

import { Link } from "react-router-dom";

export default function HeroSection(){
    return (
        <section className="hero">
            <div className="content" style={{justifyContent: "flex-end"}}>
                <div className="wrapper" style={{width: "100%"}}>
                    <div className="flex-web-block-mobile" style={{flexDirection: "column"}}>
                        <div>
                            <p className="maintxt" style={{textShadow: "1px 2px 3px rgba(0, 0, 0, 0.3)", textAlign: "center", fontFamily: "'Times New Roman', Times, serif"}}>
                                CASH 4 HOMES<br/>
                                <span>NEED TO SELL YOUR HOME FAST?</span><br/><br/>
                                <a href="#apply-form"><span style={{color: "white", padding: 15, borderRadius: 8, fontSize: 13, cursor: "pointer", backgroundColor: "orange"}}>
                                CLICK HERE GET STARTED.</span></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}