import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import ReactPixel from 'react-facebook-pixel';

//components
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/login/LoginPage";
import SignupPage from './pages/signup/SignupPage';
import PrivateLendersPage from './pages/privatelenders/PrivateLendersPage';
import PinSoftwarePage from './pages/pinsoftware/PinSoftwarePage';
import ApplyNowPage from './pages/applynow/ApplyNowPage';
import ContactPage from './pages/contact/ContactPage';
import ProgramsPage from './pages/programs/ProgramsPage';
import FixAndFlipPage from './pages/fixandflip/FixAndFlipPage';
import BrokersPage from './pages/brokers/BrokersPage';
import AboutPage from './pages/about/AboutPage';

const App = () => (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/programs" component={ProgramsPage} />
        <Route path="/brokers" component={BrokersPage} />
        <Route path="/fix-and-flip" component={FixAndFlipPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/signup" component={SignupPage} />
        <Route path="/private-lenders" component={PrivateLendersPage} />
        <Route path="/pin-software" component={PinSoftwarePage} />
        <Route path="/apply-now" component={ApplyNowPage} />
      </Switch>
    </Router>
);

//const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
//ReactPixel.init('yourPixelIdGoesHere', advancedMatching, options);
ReactPixel.init('865780041060468', options);

ReactPixel.pageView(); // For tracking page view
//ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
//ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
//ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
//ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
ReactPixel.revokeConsent();
export default App;