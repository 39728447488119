import { Link } from "react-router-dom"

export default function HeroSection(){
    return (<section className="hero login-hero">
        <div className="content">
            <div className="wrapper" style={{width: "100%"}}>
                <p style={{textAlign: "center", color: "white", fontSize: 26, fontWeight: "bolder"}}>
                    Login<br/>
                </p>
                <div className="login-form-container">
                    <div className="form-input">
                        <i className="fa-solid fa-envelope"></i>
                        <input type="email" placeholder="Email" />
                    </div>
                    <div className="form-input">
                        <i className="fa-solid fa-key"></i>
                        <input type="password" placeholder="Password" />
                    </div>
                    <div className="primary-button login-btn">
                        Login
                    </div>
                    <Link to="/signup"><p style={{color: "orange", textAlign: "center", cursor: "pointer", marginTop: 20, fontSize: 14}}>
                        Dont have an account?
                        <span style={{marginLeft: 10, color: "lightgreen"}}>
                            Signup now</span>
                    </p></Link>
                </div>
            </div>
        </div>
    </section>)
}