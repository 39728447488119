import $ from "jquery";
import { useState } from 'react';

function changeFormSection(section){
    
    //window.scrollTo(0,0);
    //$("html, body").animate({scrollTop : 0},400);

    //document.getElementById("about-you-form-section").style.display="none";
    $("#about-you-form-section").slideUp();
    //document.getElementById("income-form-section").style.display="none";
    $("#income-form-section").slideUp();
    //document.getElementById("banking-form-section").style.display="none";
    $("#banking-form-section").slideUp();
    //document.getElementById("signature-form-section").style.display="none";
    $("#signature-form-section").slideUp();

    if(section === "about-you"){
        Array.from(document.querySelectorAll(".apply-form-multi-step-indicators .each-step")).forEach(each=>{
            each.classList.remove("active");
        });
        document.getElementById("about-you-indicator-step").classList.add("active");
        //document.getElementById("about-you-form-section").style.display="block";
        $("#about-you-form-section").slideDown();
    }else if(section === "income"){
        Array.from(document.querySelectorAll(".apply-form-multi-step-indicators .each-step")).forEach(each=>{
            each.classList.remove("active");
        });
        document.getElementById("about-you-indicator-step").classList.add("active");
        document.getElementById("income-indicator-step").classList.add("active");
        //document.getElementById("income-form-section").style.display="block";
        $("#income-form-section").slideDown();
    }else if(section === "banking"){
        Array.from(document.querySelectorAll(".apply-form-multi-step-indicators .each-step")).forEach(each=>{
            each.classList.add("active");
        });
        document.getElementById("signature-indicator-step").classList.remove("active");
        //document.getElementById("banking-form-section").style.display="block";
        $("#banking-form-section").slideDown();
    }else if(section === "signature"){
        Array.from(document.querySelectorAll(".apply-form-multi-step-indicators .each-step")).forEach(each=>{
            each.classList.add("active");
        });
        //document.getElementById("signature-form-section").style.display="block";
        $("#signature-form-section").slideDown();
    }
}

export default function MainSection(){

    const [ formData, setformData ] = useState({
        type: "Single Family House",
        worth: "Less Than $100,000",
        when: "As Soon As Possible",
        prop_address: "",
        num_of_bedrooms: "",
        num_of_bathrooms: "",
        no_obligation_offer: true,
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
    });

    /*
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
        <script>
        hbspt.forms.create({
            region: "na1",
            portalId: "20233871",
            formId: "b4d303fa-57b4-44ba-b9c6-104b666fa6dd"
        });
        </script>
    */

    //Example AJAX call using the formv3 endpoint: 
    function submitFormData(){
        // Create the new request 
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/20233871/b4d303fa-57b4-44ba-b9c6-104b666fa6dd'
        
        // Example request JSON:
        //var Datetime = "2014-07-14T09:34:47.000Z"; //eg. 1517927174000
        const timeStamp = new Date().getTime()
        var data = {
            "submittedAt": timeStamp,
            "fields": [
                {
                "objectTypeId": "0-1",
                "name": "email",
                "value": formData.email
                },
                {
                "objectTypeId": "0-1",
                "name": "phone",
                "value": formData.phone
                },
                {
                "objectTypeId": "0-1",
                "name": "firstname",
                "value": formData.first_name
                },
                {
                "objectTypeId": "0-1",
                "name": "lastname",
                "value": formData.last_name
                },
                /*{
                "objectTypeId": "0-1",
                "name": "type",
                "value": formData.type
                },
                {
                "objectTypeId": "0-1",
                "name": "worth",
                "value": formData.worth
                },
                {
                "objectTypeId": "0-1",
                "name": "when",
                "value": formData.when
                },
                {
                "objectTypeId": "0-1",
                "name": "when",
                "value": formData.when
                },*/
                {
                "objectTypeId": "0-1",
                "name": "state",
                "value": formData.prop_address
                },
                /*{
                "objectTypeId": "0-1",
                "name": "numberofbedrooms",
                "value": formData.num_of_bedrooms
                },
                {
                "objectTypeId": "0-1",
                "name": "numberofbathrooms",
                "value": formData.num_of_bathrooms
                },
                {
                    "objectTypeId": "0-1",
                    "name": "nooblifationoffer",
                    "value": formData.no_obligation_offer
                }*/
            ],
            "context": {
                //"hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                "pageUri": "https://mioymcash4homes.info/",
                "pageName": "MIOYM Properties"
            },
            "legalConsentOptions":{ // Include this object when GDPR options are enabled
                "consent":{
                    "consentToProcess":true,
                    "text":"I agree to allow MIOYM Properties to store and process my personal data.",
                    "communications":[
                        {
                        "value":true,
                        "subscriptionTypeId":999,
                        "text":"I agree to receive marketing communications from MIOYM Properties."
                        }
                    ]
                }
            }
        }

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function() {
            if(xhr.readyState == 4 && xhr.status == 200) { 
                alert(xhr.responseText); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState == 4 && xhr.status == 400){ 
                alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
            } else if (xhr.readyState == 4 && xhr.status == 403){ 
                alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState == 4 && xhr.status == 404){ 
                alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
            }
        }


        // Sends the request 
        
        xhr.send(final_data)
    }

    function proptyeOnInput(type){
        setformData({...formData, type})
    }

    function propWorthOnInput(worth){
        setformData({...formData, worth})
    }

    function propWhenOnInput(when){
        setformData({...formData, when})
    }

    function propNoObligationOnInput(no_obligation_offer){
        setformData({...formData, no_obligation_offer})
    }

    function propAddressOnInput(e){
        setformData({...formData, prop_address: e.target.value})
    }

    function propBedNumOnInput(e){
        setformData({...formData, num_of_bedrooms: e.target.value});
    }

    function propBathNumOnInput(e){
        setformData({...formData, num_of_bathrooms: e.target.value});
    }

    function firstNameOnInput(e){
        setformData({...formData, first_name: e.target.value});
    }

    function lastNameOnInput(e){
        setformData({...formData, last_name: e.target.value});
    }

    function phoneNumOnInput(e){
        setformData({...formData, phone: e.target.value});
    }

    function emailOnInput(e){
        setformData({...formData, email: e.target.value});
    }

    return (
        <main className="apply-page-main" style={{width: "100%", padding: 0, marginTop: 50}}>
            <div id="apply-form" className="wrapper">
                <h1 style={{color: "orange", textAlign: "center", fontSize: 25}}>
                    GET A COMPETITIVE OFFER FOR YOUR HOME</h1>
                <p style={{color: 'white', textAlign: "center", marginBottom: 25}}>
                    Answer a few short questions to find out how much we can offer.
                </p>
                <div className="apply-page-main-content">
                    <div className="apply-page-main-content-form-section" style={{padding: "20px 10px", width: "calc(100% - 20px)", border: "1px solid rgba(255,255,255,0.2)"}}>
                        <div className="apply-form-multi-step-indicators">
                            <div id="about-you-indicator-step" className="each-step active">
                                <p>Type</p>
                                <div className="track end first"></div>
                                <div className="pointer">
                                    <i className="fa-solid fa-check"></i>
                                </div>
                            </div>
                            <div id="income-indicator-step" className="each-step">
                                <p>Worth</p>
                                <div className="track"></div>
                                <div className="pointer">
                                    <i className="fa-solid fa-exclamation-triangle"></i>
                                </div>
                            </div>
                            <div id="banking-indicator-step" className="each-step">
                                <p>When</p>
                                <div className="track"></div>
                                <div className="pointer">
                                    <i className="fa-solid fa-check"></i>
                                </div>
                            </div>
                            <div id="signature-indicator-step" className="each-step">
                                <p>Finish</p>
                                <div className="track end"></div>
                                <div className="pointer">
                                    <i className="fa-solid fa-exclamation-triangle"></i>
                                </div>
                            </div>
                        </div>
                        <div id="about-you-form-section" style={{paddingTop: 40, maxWidth: 800, margin: "auto"}}>
                            <p style={{fontWeight: "bolder", color: "white", marginBottom: 20 , textAlign: "center"}}>What type of property are you selling?</p>
                            <div className="property-types-selection_area">
                                <div className="property-types-selection_area_section">
                                    <div onClick={()=>proptyeOnInput("Single Family House")} className={"property-types-selection_item " + (formData.type==="Single Family House" && "active")}>
                                        <div>
                                            <input checked={formData.type==="Single Family House"}
                                             id="radio_1" type="radio" name="prop-type-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Single Family House</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>proptyeOnInput("Condo")} className={"property-types-selection_item " + (formData.type==="Condo" && "active")}>
                                        <div>
                                            <input checked={formData.type==="Condo"}
                                             id="radio_2" type="radio" name="prop-type-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Condo</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>proptyeOnInput("Multifamily")} className={"property-types-selection_item " + (formData.type==="Multifamily" && "active")}>
                                        <div>
                                            <input checked={formData.type==="Multifamily"}
                                             id="radio_3" type="radio" name="prop-type-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Multifamily</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="property-types-selection_area_section">
                                    <div onClick={()=>proptyeOnInput("Townhouse")} className={"property-types-selection_item " + (formData.type==="Townhouse" && "active")}>
                                        <div>
                                            <input checked={formData.type==="Townhouse"}
                                             id="radio_4" type="radio" name="prop-type-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Townhouse</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>proptyeOnInput("Mobile Home")} className={"property-types-selection_item " + (formData.type==="Mobile Home" && "active")}>
                                        <div>
                                            <input checked={formData.type==="Mobile Home"}
                                             id="radio_5" type="radio" name="prop-type-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Mobile Home</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>proptyeOnInput("Not Sure")} className={"property-types-selection_item " + (formData.type==="Not Sure" && "active")}>
                                        <div>
                                            <input checked={formData.type==="Not Sure"}
                                             id="radio_6" type="radio" name="prop-type-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Not Sure</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 0"}}>
                                <div style={{border: "1px solid rgba(255,255,255,0.2)", padding: 20, borderRadius: 7, cursor: "pointer"}}>
                                    <i style={{color: "rgba(255,255,255,0.2)", fontSize: 28, }} className="fa-solid fa-arrow-left"></i>
                                </div>
                                <div onClick={()=>changeFormSection("income")} style={{border: "1px solid rgba(255,255,255,0.2)", padding: 20, borderRadius: 7, cursor: "pointer"}}>
                                    <i style={{color: "orange", fontSize: 28, }} className="fa-solid fa-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div id="income-form-section" style={{paddingTop: 40, display: "none", maxWidth: 800, margin: "auto"}}>
                        <p style={{fontWeight: "bolder", color: "white", marginBottom: 20 , textAlign: "center"}}>
                            How much do you think it is worth?</p>
                            <div className="property-types-selection_area">
                                <div className="property-types-selection_area_section">
                                    <div onClick={()=>propWorthOnInput("Less Than $100,000")} className={"property-types-selection_item " + (formData.worth==="Less Than $100,000" && "active")}>
                                        <div>
                                            <input id="radio_7" checked={formData.worth==="Less Than $100,000"} type="radio" name="prop-worth-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Less Than $100,000</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>propWorthOnInput("$250,000 - $500,000")} className={"property-types-selection_item " + (formData.worth==="$250,000 - $500,000" && "active")}>
                                        <div>
                                            <input id="radio_8" checked={formData.worth==="$250,000 - $500,000"} type="radio" name="prop-worth-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>$250,000 - $500,000</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>propWorthOnInput("More Than $1,000,000")} className={"property-types-selection_item " + (formData.worth==="More Than $1,000,000" && "active")}>
                                        <div>
                                            <input id="radio_9" checked={formData.worth==="More Than $1,000,000"} type="radio" name="prop-worth-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>More Than $1,000,000</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="property-types-selection_area_section">
                                    <div onClick={()=>propWorthOnInput("$100,000 - $250,000")} className={"property-types-selection_item " + (formData.worth==="$100,000 - $250,000" && "active")}>
                                        <div>
                                            <input id="radio_10" checked={formData.worth==="$100,000 - $250,000"} type="radio" name="prop-worth-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>$100,000 - $250,000</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>propWorthOnInput("$500,000 - $1,000,000")} className={"property-types-selection_item " + (formData.worth==="$500,000 - $1,000,000" && "active")}>
                                        <div>
                                            <input id="radio_11" checked={formData.worth==="$500,000 - $1,000,000"} type="radio" name="prop-worth-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>$500,000 - $1,000,000</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>propWorthOnInput("Not Sure")} className={"property-types-selection_item " + (formData.worth==="Not Sure" && "active")}>
                                        <div>
                                            <input id="radio_12" checked={formData.worth==="Not Sure"} type="radio" name="prop-worth-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Not Sure</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 0"}}>
                                <div onClick={()=>changeFormSection("about-you")} style={{border: "1px solid rgba(255,255,255,0.2)", padding: 20, borderRadius: 7, cursor: "pointer"}}>
                                    <i style={{color: "orange", fontSize: 28, }} className="fa-solid fa-arrow-left"></i>
                                </div>
                                <div onClick={()=>changeFormSection("banking")} style={{border: "1px solid rgba(255,255,255,0.2)", padding: 20, borderRadius: 7, cursor: "pointer"}}>
                                    <i style={{color: "orange", fontSize: 28, }} className="fa-solid fa-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div id="banking-form-section" style={{paddingTop: 40, display: "none", maxWidth: 800, margin: "auto"}}>
                        <p style={{fontWeight: "bolder", color: "white", marginBottom: 20 , textAlign: "center"}}>
                            How soon are you looking to sell?</p>
                            <div className="property-types-selection_area">
                                <div className="property-types-selection_area_section">
                                    <div onClick={()=>propWhenOnInput("As Soon As Possible")} className={"property-types-selection_item " + (formData.when==="As Soon As Possible" && "active")}>
                                        <div>
                                            <input id="radio_1" checked={formData.when==="As Soon As Possible"} type="radio" name="prop-sell-when-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>As Soon As Possible</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>propWhenOnInput("6-12 months")} className={"property-types-selection_item " + (formData.when==="6-12 months" && "active")}>
                                        <div>
                                            <input id="radio_2" checked={formData.when==="6-12 months"} type="radio" name="prop-sell-when-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>6-12 months</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>propWhenOnInput("1-3 months")} className={"property-types-selection_item " + (formData.when==="1-3 months" && "active")}>
                                        <div>
                                            <input id="radio_3" checked={formData.when==="1-3 months"} type="radio" name="prop-sell-when-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>1-3 months</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="property-types-selection_area_section">
                                    <div onClick={()=>propWhenOnInput("12+ months")} className={"property-types-selection_item " + (formData.when==="12+ months" && "active")}>
                                        <div>
                                            <input id="radio_4" checked={formData.when==="12+ months"} type="radio" name="prop-sell-when-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>12+ months</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>propWhenOnInput("3-6 months")} className={"property-types-selection_item " + (formData.when==="3-6 months" && "active")}>
                                        <div>
                                            <input id="radio_5" checked={formData.when==="3-6 months"} type="radio" name="prop-sell-when-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>3-6 months</span>
                                        </div>
                                    </div>
                                    <div onClick={()=>propWhenOnInput("Not Sure")} className={"property-types-selection_item " + (formData.when==="Not Sure" && "active")}>
                                        <div>
                                            <input id="radio_6" checked={formData.when==="Not Sure"} type="radio" name="prop-sell-when-input" style={{marginRight: 10, width: 15, height: 15}}/>
                                            <span>Not Sure</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 0"}}>
                                <div onClick={()=>changeFormSection("income")} style={{border: "1px solid rgba(255,255,255,0.2)", padding: 20, borderRadius: 7, cursor: "pointer"}}>
                                    <i style={{color: "orange", fontSize: 28, }} className="fa-solid fa-arrow-left"></i>
                                </div>
                                <div onClick={()=>changeFormSection("signature")} style={{border: "1px solid rgba(255,255,255,0.2)", padding: 20, borderRadius: 7, cursor: "pointer"}}>
                                    <i style={{color: "orange", fontSize: 28, }} className="fa-solid fa-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div id="signature-form-section" style={{paddingTop: 40, display: "none", maxWidth: 800, margin: "auto"}}>
                            <p style={{fontWeight: "bolder", color: "white", marginBottom: 20, textAlign: "center"}}>
                                Add Your Property Information</p>
                            <input className="bare-form-input" type="text" placeholder="Property Address" />
                            <input onInput={propAddressOnInput} className="bare-form-input" type="text" placeholder="City" />
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <input onChange={propBedNumOnInput} className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="How many bedrooms?" />
                                <input onChange={propBathNumOnInput} className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="How many bathrooms?" />
                            </div>
                            
                            <p style={{color: "white", textAlign: "center"}}>
                                Would you like to receive a no-obligation offer for your house?
                            </p>
                            <div className="property-types-selection_area">
                                <div className="property-types-selection_area_section">
                                    <div onClick={()=>propNoObligationOnInput(true)} className={"property-types-selection_item " + (formData.no_obligation_offer && "active")}>
                                        <input id="no-oblig-input-1" checked={formData.no_obligation_offer} type="radio" name="no-obligation-input"/>
                                        <span>Yes</span>
                                    </div>
                                </div>
                                <div className="property-types-selection_area_section">
                                    <div onClick={()=>propNoObligationOnInput(false)} className={"property-types-selection_item " + (!formData.no_obligation_offer && "active")}>
                                        <input id="no-oblig-input-2" checked={!formData.no_obligation_offer} type="radio" name="no-obligation-input"/>
                                        <span>No</span>
                                    </div>
                                </div>
                            </div>
                            <p style={{color: "white", textAlign: "center"}}>
                                Add your infomation</p>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <input onChange={firstNameOnInput} className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="First Name" />
                                <input onChange={lastNameOnInput} className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Last Name" />
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <input onChange={phoneNumOnInput} className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Phone Number" />
                                <input onChange={emailOnInput} className="bare-form-input" style={{width: "calc(50% - 30px)"}} type="text" placeholder="Email" />
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 0"}}>
                                <div onClick={()=>changeFormSection("banking")} style={{border: "1px solid rgba(255,255,255,0.2)", padding: 20, borderRadius: 7, cursor: "pointer"}}>
                                    <i style={{color: "orange", fontSize: 28, }} className="fa-solid fa-arrow-left"></i>
                                </div>
                                <div onClick={submitFormData} style={{border: "1px solid rgba(255,255,255,0.2)", backgroundColor: "darkslateblue", color: "white", padding: 20, borderRadius: 7, cursor: "pointer"}}>
                                    Submit
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="apply-page-main-content-other-side" style={{display: "none"}}>
                        <div className="parts">
                            <p style={{color: "skyblue", fontWeight: "bolder", margin: 0, marginBottom: 5}}>We put Some Topic Here</p>
                            <p className="desctxt" style={{fontSize: 13}}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            </p>
                        </div>
                        <div className="parts">
                            <p style={{color: "skyblue", fontWeight: "bolder", margin: 0, marginBottom: 5}}>
                                <i style={{marginRight: 10, fontSize: 25, color: "orange"}} className="fa-solid fa-phone"></i>
                                Contact Us Now</p>
                            <p style={{color: "white", fontSize: 14}}>888 97 MIOYM | 888.976.4696</p>
                        </div>
                        <div className="parts">
                            <p style={{color: "skyblue", fontWeight: "bolder", margin: 0, marginBottom: 10}}>
                                <i style={{marginRight: 10, fontSize: 25, color: "orange"}} className="fa-solid fa-lock"></i>
                                We've Got You Covered</p>
                            <p className="desctxt" style={{fontSize: 13}}>
                                Any information you provide is kept secure and private
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}