import { Component } from "react";

import SignupHeroSection from "../../components/SignupHeroSection";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class SignupPage extends Component {
    constructor(props){
        super(props);
    }
    render(){
       return (
           <>
                <Header />
                <SignupHeroSection />
                <Footer />
            </>
        )
    }
}

export default SignupPage;