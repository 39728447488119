import { Component } from "react";

import PrivateLendersHeroSection from "../../components/PrivateLendersHeroSection";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

class PrivateLendersPage extends Component {
    constructor(props){
        super(props);
    }
    render(){
       return (
           <>
                <Header />
                <PrivateLendersHeroSection />
                <Footer />
            </>
        )
    }
}

export default PrivateLendersPage;