import { Component } from 'react'

//components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FixAndFlipMainSection from './sections/FixAndFlipMainSection';

class FixAndFlipPage extends Component{
    constructor(props){
        super(props);
    }


    render(){
        return(
            <>
                <section className='page-container'>
                    <Header header="fix-and-flip" />
                    <FixAndFlipMainSection />
                    <Footer />
                </section>
            </>
        );
    }
}

export default FixAndFlipPage;