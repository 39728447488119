import { Component } from "react";
import LineChart from "../../../components/Chart";
import connectedpeople from "../../../images/connectedpeople.jpg";

import MainSection from "../../applynow/sections/MainSection";

export default class Main extends Component{
    constructor(props){
        super(props);
        
    }
    

    componentDidMount(){
    }

    render(){
        return (<section>
            <div className="wrapper">
                <div className="testimonies-section">
                    <div className="content">
                        <MainSection />
                        <div className="section" style={{display: "none"}}>
                            <p className="maintxt">
                                Reviews
                            </p>
                            <div className="item">
                                <p className="maintxt">
                                    <span>Anthony Gomez</span> 
                                </p>
                                <p className="rating-stars">
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                </p>
                                <p className="desctxt" style={{color: "white"}}>
                                    Ac diam risus, consectetur mattis venenatis bibendu nisi interdum aenean. Malesuada massa luctus facilisis, viverra ut blandit, ligula nisl aliquam vivamus dapibus est gravida bibendum
                                </p>
                                <p className="smalltxt" style={{fontSize: 12, color: "white"}}>
                                    March 23, 2022
                                </p>
                            </div>
                            <div className="item">
                                <p className="maintxt">
                                    <span>Phillip Andrew</span> 
                                </p>
                                <p className="rating-stars">
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                </p>
                                <p className="desctxt" style={{color: "white"}}>
                                    Ac diam risus, consectetur mattis venenatis bibendu nisi interdum aenean. Malesuada massa luctus facilisis, viverra ut blandit, ligula nisl aliquam vivamus dapibus est gravida bibendum
                                </p>
                                <p className="smalltxt" style={{fontSize: 12, color: "white"}}>
                                    March 23, 2022
                                </p>
                            </div>
                            <p className="read-more-txt" style={{cursor: 'pointer', marginTop: 40}}>READ MORE</p>
                        </div>
                        <div className="section box" style={{display: "none", width: "100%"}}>
                            <h1 style={{color: "orange", margin: "20px", textAlign: "center", fontSize: 25, marginBottom: 10}}>
                                Get a Competitive Offer for Your Home
                            </h1>
                            <p style={{color: 'lightgreen', margin: "0 20px", textAlign: "center"}}>
                                Regardless of the condition your property is in or the situation you're dealing with, we'd like to try and find a solution that works for you. Fill out the following form so we can get in touch and get started.
                            </p>
                            <div style={{marginLeft: -5, marginRight: 5, backgroundColor: "rgb(21,25,33)", boxShadow: "0 0 10px rgba(0,0,0,0.5)", border: "1px solid rgba(255,255,255,0.1)", borderRadius: 7, padding: 10, marginTop: 20}}>
                                <p style={{textAlign: "center", color: "white", fontWeight: "bolder", fontSize: 19}}>LET'S GET STARTED.</p>
                                <div className="testimonial-register-forms-contain">
                                    <div className="input-item">
                                        <i className="fa-solid fa-envelope"></i>
                                        <input placeholder="Property Address" />
                                    </div>
                                    <div className="input-item">
                                        <i className="fa-solid fa-envelope"></i>
                                        <input placeholder="Email" />
                                    </div>
                                    <div className="input-item">
                                        <i className="fa-solid fa-envelope"></i>
                                        <input placeholder="Phone" />
                                    </div>
                                    <div className="testimonial-register-btn" style={{width: 200, borderRadius: 7}}>
                                        <i className="fa-solid fa-square-check"></i>
                                        Get a Cash Offer
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="charts-section" style={{display: "none"}}>
                    <div className="content">
                        <div className="section box">
                            <LineChart />
                        </div>
                        <div className="section">
                            <p className="maintxt">
                                Our Data
                            </p>
                            <p className="desctxt">
                                Ac diam risus, consectetur mattis venenatis bibendu nisi interdum aenean. Malesuada massa luctus facilisis, viverra ut blandit, ligula nisl aliquam vivamus dapibus est gravida bibendum
                            </p>
                            <p className="read-more-txt" style={{cursor: 'pointer', marginTop: 40}}>READ MORE</p>
                            <div className="counts-section" style={{position: "relative", marginTop: 20}}>
                                <div className="counts-section-content">
                                    <div>
                                        <p>5000+ companies</p>
                                        <p>Recieved Help</p>
                                    </div>
                                    <div>
                                        <p>$200+ million</p>
                                        <p>Loans Given</p>
                                    </div>
                                    <div>
                                        <p>47+ states</p>
                                        <p>Nationwide</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}